






import { computed, defineComponent } from "@vue/composition-api";

import PersonalCustomCodeAgreement from "@/components/Order/PersonalCustomCodeAgreement.vue";

type TermType = "abroad";

export default defineComponent({
  components: {
    PersonalCustomCodeAgreement,
  },
  props: {
    termType: {
      type: String as () => TermType,
    },
  },
  setup(props) {
    const agreementComponentName = computed(() => {
      const agreementComponentMap = {
        abroad: "PersonalCustomCodeAgreement",
      };

      const agreementComponentKey = props.termType ?? "abroad";

      return agreementComponentMap[agreementComponentKey];
    });

    return {
      agreementComponentName,
    };
  },
});
