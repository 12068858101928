
















import { defineComponent } from "@vue/composition-api";

import OrderTersmContainer from "@/components/Order/OrderTermsContainer.vue";

export default defineComponent({
  components: {
    OrderTersmContainer,
  },
  emits: ["click:back"],
});
